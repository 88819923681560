<template>
  <b-container class="mt-3">
    <h1 class="font-weight-bold text-left mb-3">Editionsrichtlinien</h1>
    <b-row>
      <b-col cols="7">
        <div class="text-left">
         <p> Die Dokumente der Rechtsakten aus der Kanzlei Oskar Samek enthalten unterschiedliche Textarten, weshalb für die verschiedenen Textgruppen auch unterschiedliche Editionsprinzipien aufgestellt wurden, die hier mitgeteilt werden. Außerdem bestehen die Akten aus verschiedenen Materialitätstypen, wobei der Großteil als Typoskripte überliefert ist. Darüber hinaus finden sich Handschriften sowie zahlreiche maschinen- und handschriftlich ausgefüllte vorbedruckte Formulare.</p>
<p>Die umfangreichen Bearbeitungen des Karl Kraus-Archivs durch die Jahrzehnte hindurch führten dazu, dass ein kleiner Teil der Dokumente nur noch als Kopien in den Mappen selbst vorhanden ist. Ziel war es aber, den Rechtsaktenbestand so wiederzugeben, wie er derzeit vorliegt.</p>


<p class="font-weight-bold">Grundlage der edierten Dokumente</p>

<p>Als Textgrundlage wurde generell das veröffentlichte bzw. abgeschickte Dokument verwendet. Meistens liegt in den Rechtsakten nur bei den empfangenen Dokumenten der gegnerischen Seite bzw. der Gerichte das tatsächlich abgeschickte Dokument vor. Aus der Samek-Kanzlei sind in der Regel nur die Durchschläge der abgeschickten Dokumente, mitunter auch nur Vorstufen bzw. Fassungen, überliefert. Aus diesem Grund konnte nicht immer mit Sicherheit festgestellt werden, welcher Brief, welche Klage- oder Berufungsschrift tatsächlich abgeschickt wurde. Liegt das Dokument in mehreren, voneinander abweichenden Exemplaren vor, wurden die Varianten ediert, da diese den mitunter aufwendigen Arbeitsprozess und das Zwiegespräch zwischen Anwalt und Klient abbilden können. In diesen Fällen bildet die Textgrundlage dasjenige Exemplar, das den weitest fortgeschrittenen Arbeitsprozess darstellt. Auf Differenzen wird im Variantenapparat verwiesen. Sind die Textunterschiede zweier Exemplare erheblich, wurden beide Textzeugen ediert. </p>
<p>Dokumentabschriften sind nur dann ediert, wenn sie das einzige überlieferte Exemplar darstellen. Dabei werden Schreib- bzw. Tippfehler ignoriert, da nicht die Fehleranfälligkeit der Schreiber:innen dokumentiert werden sollte. Gleiches Verfahren wurde bei den juristischen Dokumenten aus den Gerichten, etwa bei Urteilsschriften, angewendet, da hier kein Autor, wie es bspw. bei Briefen der Fall ist, zu bestimmen war, und diese Edition primär den Informationsgehalt des Urteils vermitteln soll.</p>
<p>Innerhalb der Projektlaufzeit blieb nach Abschluss der Kodierung lediglich Zeit für einen Lektorats- bzw. Korrektoratsdurchgang.</p>


<p class="font-weight-bold">Allgemeine Transkriptionsregeln</p>

<p>Die Dokumente wurden zeilengenau transkribiert. Bei mehrspaltigem Satz werden die Spalteninhalte durch Absätze voneinander getrennt.</p>
<p>Späte Änderungen, die oft handschriftlich erfolgt sind, wurden stets berücksichtigt, da sie die Korrekturabläufe transparent machen und zeigen, dass Anwalt und Mandant, mitunter auch sehr intensiv, an den Texten im Nachhinein gearbeitet haben. Tippfehler, die sofort geändert wurden, sind hingegen nicht ediert, da nicht die Schreibfähigkeit der Schreiber:innen dokumentiert werden soll. Falsche Schreibweisen von Namen wurden allerdings konsequent wiedergegeben. Grundsätzlich wurden nur den Wortlaut betreffende sowie spätere handschriftliche interpunktionelle und orthografische Änderungen wiedergegeben, Sofortänderungen der Orthografie und Interpunktion wurden hingegen nicht aufgenommen.</p>
<p>Der Briefkopf in Korrespondenzen (der neben dem Datum, den Adressen der am Schriftstück beteiligten Personen ebenso einen Betreff und eine Diktatsigle enthalten kann) wurde in den Metadaten erfasst, nicht aber transkribiert. Stempel der gerichtlichen Dokumente wurden nicht transkribiert, aber in den Metadaten erfasst.</p>
<p>Sperrungen und Unterstreichungen sind grundsätzlich als solche differenziert wiedergegeben, weil beide Hervorhebungen mitunter auch im selben Exemplar vorkommen können. Anführungszeichen sind einheitlich und typografisch korrekt (also nicht mimetisch) wiedergegeben, um die Lesbarkeit der Dokumente, in denen häufig zitiert wurde, zu gewährleisten. Auch die zum Teil gebräuchliche Schreibweise bei Preisangaben durch zwei Bindestriche wurde durch ein Halbgeviert angepasst. Verschleifungen bei Unterschriften wurden aufgelöst. Seiten- und Blattzählungen, Dittografien und Kustoden werden nicht wiedergegeben. Spatien zwischen Abkürzungen wurden einheitlich nicht gesetzt. Unvollständige Datumsangaben bzw. Teile davon wurden ergänzt, da diese bei Durchschlägen oft fehlen, auf dem Typoskript allerdings auf dem vorbedruckten Briefpapier vorhanden waren. Eigentümliche Schreibweisen wie bspw. die Verwendung eines Versal-„J“ anstelle des Versal-„I“ wurden normalisiert. Editorische Hinzufügungen wurden mit eckigen Klammern gekennzeichnet. Für unleserliche Buchstaben und Wörter sind (mehrere) „[¿]“ gesetzt.</p>


<p class="font-weight-bold">Schreibmaterial</p>

<p>Wurde in einem maschinell erstellten Dokument handschriftlich geändert, so ist dieser Wechsel des Schreibmaterials in der Transkription dargestellt sowie in den Metadaten genauer beschrieben, indem Schreiberhände und Schreibmaterial mitgeteilt sind. Bei nachträglichen handschriftlichen Hinzufügungen am Dokument wurde außerdem in der Darstellung unterschieden, ob es sich um eine handschriftliche Notiz bzw. Hervorhebung durch Marginalien am Rande handelt oder ob tatsächlich eine Änderung am Text vorgenommen wurde. Handschriftlich gesetzte Unterschriften wurden nicht als solche extra ausgezeichnet. Sogenannte Kanzleivermerke, die in der Regel ebenfalls handschriftlich und meistens am Ende eines Dokuments angebracht wurden, sind einheitlich differenziert in der Transkription wiedergegeben.</p>

<p>Durch die synoptische Darstellung ist die Transkription an jeder Stelle mit dem Original überprüfbar.</p>

<p class="font-weight-bold">Nicht transkribierte Dokumente und Akten</p>

<p>Sämtliche Zeitungstexte, Originalmappen, -umschläge und Aktenvermerke sowie Kostenbestimmungen, Rückscheine, Rechnungen, Ladungen und weitere vorbedruckte Dokumente wurden nicht transkribiert, sondern mittels Faksimile dargestellt und durch die Angabe ihrer Metadaten erfasst. Die von Kraus und Samek angelegten Aktensammlungen zu Emmerich Békessy (Akten 43–59) wurden nicht ediert, da es sich hier meistens um Materialien zu Prozessen anderer Anwälte und ihrer Klienten handelt. Die Akten 204–215, bei denen es sich primär um separat abgelegte Kontextmaterialien handelt, wurden ebenfalls nicht ediert.</p>

<p class="font-weight-bold">Materialitäts- und Dokumentstypen</p>

<p>Allen edierten Dokumenten wurden in den Metadaten Materialitäts- und Dokumentstypen zugeordnet, die auf einer für das Projekt und den Aktenbestand speziell erarbeiteten Taxonomie basieren. Liegt ein Dokument in der Mappe nur als archivarische Kopie vor, wurde der Materialitätstyp „Kopie“ ergänzt.</p>

<p class="font-weight-bold">Intertextualität und weitere Referenzen</p>

<p>Der Projektausrichtung und zentralen Forschungsfrage gemäß wurden im transkribierten Text mehrere Auszeichnungen vorgenommen. Alle identifizierbaren Werke und Zitate aus Rechtstexten, die in <a target="_blank" rel="noopener noreferrer" href="https://alex.onb.ac.at/"><i>ALEX</i></a> (Service der Österreichischen Nationalbibliothek) mit Digitalisaten zu historischen Rechts- und Gesetzestexten enthalten sind, wurden referenziert. Nicht ermittelte Werke und Zitate wurden als solche ausgezeichnet. Außerdem wurden Zitate und Werke aus der <a target="_blank" rel="noopener noreferrer" href="https://fackel.oeaw.ac.at"><i>Fackel</i></a> seitengetreu referenziert. Darüber hinaus sind Zitate aus anderen, in den Rechtsakten enthaltenen Dokumenten bzw. die Dokumente selbst ausgezeichnet und referenziert.</p>
<p>Ein Sonderfall der Auszeichnung stellen jene in den Akten enthaltenen Textpassagen dar, die zu einem späteren Zeitpunkt in der <i>Fackel</i> veröffentlicht wurden und somit Teil eines literarischen Textes wurden. Diese sogenannten <i>Fackel</i>-Intertexte sind, soweit sie identifizierbar waren, rot hervorgehoben und mit einem Verweis auf die entsprechende Stelle in der <i>Fackel</i> referenziert.</p>

<p>In den Transkriptionen werden neben den Intertextualitätsreferenzen weitere Entitäten wie Personen, Orte, Institutionen ausgezeichnet.</p>

<p>Für Beispiele zu den beschriebenen Auszeichnungen siehe <a
        target="_blank"
        rel="noopener noreferrer"
        :href="`${publicPath}textkritische_zeichen_rechtsakten_karl_kraus.pdf`"
        >textkritische Zeichen</a>.</p>
        </div>
      </b-col>
      <b-col cols="5">
          <Zitierempfehlung/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Zitierempfehlung from "./Zitierempfehlung";

export default {
  name: "Editionsrichtlinien",
  components: {Zitierempfehlung},
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}
</script>

<style scoped>
main {
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  grid-column-gap: 10rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.card {
  font-size: smaller;
}

.container {
  grid-template-columns: unset;
}

.left {
  grid-column: 1/2;
}

.right {
  grid-column: 2/3;
}
.text {
  text-align: left;
}

h2 {
  text-align: left;
}

a {
  color: var(--primary-red);
}
</style>