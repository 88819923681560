import { render, staticRenderFns } from "./EntitySpan.vue?vue&type=template&id=79c6c5c3&%40click=%24emit('click'%2C%20%24event)"
import script from "./EntitySpan.vue?vue&type=script&lang=js"
export * from "./EntitySpan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports