import { render, staticRenderFns } from "./OverviewObjects.vue?vue&type=template&id=40f6b437&scoped=true"
import script from "./OverviewObjects.vue?vue&type=script&lang=js"
export * from "./OverviewObjects.vue?vue&type=script&lang=js"
import style0 from "./OverviewObjects.vue?vue&type=style&index=0&id=40f6b437&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f6b437",
  null
  
)

export default component.exports