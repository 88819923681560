import { render, staticRenderFns } from "./OverviewHandapparat.vue?vue&type=template&id=3ec4b9cc&scoped=true"
import script from "./OverviewHandapparat.vue?vue&type=script&lang=js"
export * from "./OverviewHandapparat.vue?vue&type=script&lang=js"
import style0 from "./OverviewHandapparat.vue?vue&type=style&index=0&id=3ec4b9cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec4b9cc",
  null
  
)

export default component.exports