<template>
  <div class="text-left w-75 mx-auto my-2">
    <h1 class="mb-3">Oskar Samek</h1>
    <p>Gerlinde Kainz, <a target="_blank"
        rel="noopener noreferrer"
        :href="`${publicPath}gerlinde_kainz_beitrag.pdf`">Oskar Samek und das Haus in der Reindorfgasse 18</a></p>
    <p>
      <a target="_blank"
        rel="noopener noreferrer"
        :href="`${publicPath}interview_oskar_samek.pdf`">Transkription eines Interviews mit Oskar Samek</a> (6. August 1957, <a target="_blank"
        rel="noopener noreferrer"
        href="https://www.mediathek.at/atom/08F3ACAF-13C-00238-00000CD4-08F2C29F">Audiodatei
      des Interviews</a>, Österreichische Mediathek)
    </p>
  </div>
</template>
<style scoped>
a {
  color: var(--primary-red);
}
</style>
<script>
export default {
  name: "OskarSamek",
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
};
</script>