import { render, staticRenderFns } from "./SearchResultItem.vue?vue&type=template&id=00cf7972&scoped=true"
import script from "./SearchResultItem.vue?vue&type=script&lang=js"
export * from "./SearchResultItem.vue?vue&type=script&lang=js"
import style0 from "./SearchResultItem.vue?vue&type=style&index=0&id=00cf7972&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00cf7972",
  null
  
)

export default component.exports