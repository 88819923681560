<template>
  <b-container class="mt-3">
    <h1 class="font-weight-bold text-left mb-3">Benutzungshinweise</h1>
    <b-row>
      <b-col cols="7">
        <div class="text-left">


<p class="font-weight-bold">Hinweise zur Zitation</p>

<p>Haupttitel:<br/>
  <i>Karl Kraus: Rechtsakten der Kanzlei Oskar Samek. Wissenschaftliche Edition</i>, hg. v. Johannes Knüchel und Isabel Langkabel, auf Grundlage der Vorarbeiten Katharina Pragers, unter Mitarbeit von Laura Untner, Andrea Ortner, Ingo Börner und Vanessa Hannesschläger (Wien 2022), <a rel="noopener noreferrer" href="https://www.kraus.wienbibliothek.at" target="_blank">https://www.kraus.wienbibliothek.at</a>.

</p> 
<p>
  Ein einzelnes Dokument sowie eine einzelne Akte können jeweils nach folgendem Muster zitiert werden: „Dokument 1.1, in: [Haupttitel]“ bzw. „Akte 1, in: [Haupttitel]“.

</p>  

<p class="font-weight-bold">Konkordanz</p>

<p>
  Die Konkordanz listet jene zu mehreren Akten gehörenden Dokumente bzw. Duplikate und ist nach aufsteigenden Dokumentensiglen geordnet.

</p>
<ul class="list-unstyled">  
<li>53.12 = 53.14</li>
<li>164.3 = 165.1</li>
<li>164.4 = 165.2</li>
<li>183.22 = 183.24</li>
<li>187.10 = 189.3</li>
<li>187.17 = 189.20</li>
<li>189.68 = 193.2</li>
<li>189.72 = 193.3</li>
<li>189.73 = 193.4</li>
<li>189.74 = 193.9</li>
<li>189.75 = 193.10</li>
<li>189.111 = 192.13 = 195.39</li>
<li>189.126 = 192.18</li>
<li>189.129 = 192.19</li>
<li>192.13 = 195.39</li>
<li>192.20 = 195.41</li>
<li>192.22 = 193.86 = 195.42</li>
<li>192.25 = 193.96</li>
<li>192.26 = 193.122 = 196.95</li>
<li>192.28 = 193.143 = 196.105</li>
<li>193.58 = 194.1 = 195.1</li>
<li>193.127 = 195.43 = 196.100</li>
<li>193.147 = 195.47</li>
<li>193.152 = 202.4</li>
<li>195.46 = 202.2</li>
<li>195.50 = 202.3</li>
</ul>
<p class="font-weight-bold mt-3">Abkürzungsverzeichnis</p>
<table>
  <tr>
<tr><td>AB</td><td>Augsburger Bekenntnis (evangelisch)</td></tr>
<tr><td>AbBlg.</td><td>[?]</td></tr>
<tr><td>abGB</td><td>allgemeines bürgerliches Gesetzbuch Österreich</td></tr>
<tr><td>Abt.</td><td>Abteilung</td></tr>
<tr><td>bezw.</td><td>beziehungsweise</td></tr>
<tr><td>Bb.</td><td>[?]</td></tr>
<tr><td>cr.</td><td>currens (laufend, Jahr bzw. Monat)</td></tr>
<tr><td>cts.</td><td>currentis ([des] laufenden, Jahres bzw. Monats)</td></tr>
<tr><td>d.M.</td><td>diesen Monats</td></tr>
<tr><td>dh.</td><td>dorthin</td></tr>
<tr><td>dort.</td><td>dortiges</td></tr>
<tr><td>egh.</td><td>eigenhändig</td></tr>
<tr><td>eingel.</td><td>eingelangt</td></tr>
<tr><td>ev.</td><td>evangelisch</td></tr>
<tr><td>geb.</td><td>geborene(r)</td></tr>
<tr><td>Gel.</td><td>[?]</td></tr>
<tr><td>Gen.</td><td>Genossen[?]</td></tr>
<tr><td>gf.</td><td>gefälligst</td></tr>
<tr><td>G.Z.</td><td>Geschäftszeichen</td></tr>
<tr><td>H.B.</td><td>Helvetisches Bekenntnis (evangelisch)</td></tr>
<tr><td>h.g.</td><td>[?]</td></tr>
<tr><td>I.A.</td><td>im Auftrag</td></tr>
<tr><td>kath.</td><td>katholisch</td></tr>
<tr><td>Kmzl.R.</td><td>[?]</td></tr>
<tr><td>kr. A.</td><td>kraft Amtes</td></tr>
<tr><td>KS.</td><td>[?]</td></tr>
<tr><td>ldg.</td><td>ledig</td></tr>
<tr><td>l.M.</td><td>laufenden Monats</td></tr>
<tr><td>Lg.</td><td>Landesgericht</td></tr>
<tr><td>mos.</td><td>mosaisch</td></tr>
<tr><td>m.p.</td><td>Manu propria</td></tr>
<tr><td>N.E.F.</td><td>Nichteinbringungsfall</td></tr>
<tr><td>n.e.Z.a.</td><td>[?]</td></tr>
<tr><td>nm.</td><td>nachmittags</td></tr>
<tr><td>noe</td><td>[?]</td></tr>
<tr><td>n.W.E.</td><td>[?]</td></tr>
<tr><td>OLGR</td><td>Oberlandesgerichtsrat</td></tr>
<tr><td>P.A. </td><td>Privatankläger</td></tr>
<tr><td>P.A.V.</td><td>Privatanklagevertreter</td></tr>
<tr><td>P.G.</td><td>Pressegesetz</td></tr>
<tr><td>pto.</td><td>puncto / punkto</td></tr>
<tr><td>RA</td><td>Rechtsanwalt</td></tr>
<tr><td>R.A.A.</td><td>Rechtsanwaltsanwärter</td></tr>
<tr><td>r.k.</td><td>römisch-katholisch</td></tr>
<tr><td>StBG</td><td>Strafbezirksgericht</td></tr>
<tr><td>StPO</td><td>Strafprozeßordnung</td></tr>
<tr><td>Strb.</td><td>[?]</td></tr>
<tr><td>Strfbez.</td><td>Strafbezirksgericht</td></tr> 
<tr><td>Strfs.</td><td>Strafsachen</td></tr>
<tr><td>Tgb.</td><td>[?]</td></tr>
<tr><td>unl.</td><td>unleserlich</td></tr>
<tr><td>u.e.Za.</td><td>[?]</td></tr>
<tr><td>verh. / vh.</td><td>verheiratet</td></tr>
<tr><td>v.g.g.</td><td>Verwaltungsgerichtsgesetz[?]</td></tr>
<tr><td>Vors.</td><td>Vorsitzender</td></tr>
<tr><td>V.Schule</td><td>Volksschule</td></tr>
<tr><td>W.E.E.V.</td><td>[?]</td></tr>
<tr><td>W.Er.</td><td>W[illens?]erklärung</td></tr>
<tr><td>wh.</td><td>wohnhaft</td></tr>
</table>
        </div>
      </b-col>
      <b-col cols="5">
          <Zitierempfehlung/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Zitierempfehlung from "./Zitierempfehlung";

export default {
  name: "Benutzungshinweise",
  components: {Zitierempfehlung}
};
</script>

<style scoped>
main {
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  grid-column-gap: 10rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.card {
  font-size: smaller;
}

.container {
  grid-template-columns: unset;
}

.left {
  grid-column: 1/2;
}

.right {
  grid-column: 2/3;
}
.text {
  text-align: left;
}

h2 {
  text-align: left;
}

a {
  color: var(--primary-red);
}

table tr td:nth-child(2) {
  padding-left:1rem;
}


</style>