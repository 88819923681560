<template>
  <b-card class="p-1 border-0 rounded-0">
    <b-card-text class="text-left">
      <p class="font-weight-bold mb-0">
        Karl Kraus: Rechtsakten der Kanzlei Oskar Samek. Wissenschaftliche
        Edition
      </p>
      <p>
        Zitierempfehlung:
        <i
          >Karl Kraus: Rechtsakten der Kanzlei Oskar Samek. Wissenschaftliche
          Edition,</i
        >
        hg. v. Johannes Knüchel und Isabel Langkabel, auf Grundlage der Vorarbeiten Katharina Pragers, unter Mitarbeit von Laura
        Untner, Andrea Ortner, Ingo Börner und Vanessa Hannesschläger (Wien
        2022),<br /><a
          href="https://www.kraus.wienbibliothek.at"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.kraus.wienbibliothek.at</a
        >.
      </p>
    </b-card-text>
  </b-card>
</template>
<script>
export default {
  name: "Zitierempfehlung",
};
</script>
<style scoped>
.card {
  background-color: var(--cite-card-gray);
}
</style>